import React from "react";
import { useParams } from "react-router-dom";

const RedirectorPage: React.FC = () => {
  const { path } = useParams<{ path: string }>();
  const regex = /^\d{4}$/;

  if (path && regex.test(path)) {
    window.location.replace(`https://lexim.gold/`);
    return null;
  } else {
    return <h1>404 - Not Found</h1>;
  }
};

export default RedirectorPage;
