import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RedirectorPage from "./RedirectPage";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:path" element={<RedirectorPage />} />
        <Route path="/" element={<h1>Welcome to the Lexim</h1>} />
        <Route path="*" element={<h1>404 - Not Found</h1>} />
      </Routes>
    </Router>
  );
};

export default App;
